import React from "react";

// Potential future Sign In page.
const SigninPage = () => {
  return (
    <div>
      <h1>Sign in page</h1>
    </div>
  );
};

export default SigninPage;
